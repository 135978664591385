import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { JwtHelperService } from "@auth0/angular-jwt";
import { map, catchError, finalize, tap } from 'rxjs/operators';
import { UtilityService } from '../utility.service';
import { Router } from '@angular/router';
import { NetworkService } from '../network.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private _router:Router, private http: HttpClient, public jwtHelper: JwtHelperService,private _utility:UtilityService, private _network: NetworkService) { }
  
  login(user:string, pwd:string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
     var log = {
        "email": user,
        "password": pwd,
      }
    
    return this.http.post<any>(environment.personApiUrl+'/api/v1/login', log, { headers }).pipe(
      map((_) => {
       if (_.data) {
          localStorage.setItem('ut',_.data.token);
          localStorage.setItem('ud',JSON.stringify(_.data.user));
          return true;
       }
       return false;   
      }),
      catchError(error => {
        return throwError(error);

      }),
      finalize(() => {
      })
    );
  }
  async logout(): Promise<any> {
    const token=localStorage.getItem('rt');
    if(token){
      let params = new HttpParams()
      .set("refreshToken",token);
    const result = await this._network.getWithOptions("v1/Logout", params);
    return result;
    }
    this._router.navigate(['auth/login'])
  }
  async refresh(): Promise<any> {
    const token=localStorage.getItem('rt')
    if(token){
      let params = new HttpParams()
      .set("refreshToken", token);
    const result = await this._network.getWithOptions("/auth/RefreshToken", params);
    return result;
    }
    return null
  }
  validateResetPasswordToken(param:any) {
    let params = new HttpParams()
      .set("code",param); 
      let  httpHeaders = new HttpHeaders()
      let params1 = new HttpParams();
      params1 = params;
      var options1: {
        headers?: HttpHeaders;
        observe?: "body";
        params?: HttpParams;
        reportProgress?: boolean;
        withCredentials?: boolean;
      } = {
        headers: httpHeaders,
        params: params1
      };
      return this.http.get<any>(environment.apiUrl+'/auth/ValidateResetPasswordToken', options1).pipe(
        map((res) => {
          if(res.code==1){
            this._router.navigate(['new-password'])
            this._utility.resetToken=param;
          }        
        }),
        catchError(error => {
          return throwError(error);
  
        }),
      )
  }
  updatePassword(model:any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post<any>(environment.personApiUrl+'/api/v1/updatepassword', model, { headers }).pipe(
      map((res) => {
          if(res.code==1){
            this._router.navigate(['login'])
          }
          return res;
      }),
      catchError(error => {        
        return throwError(error);

      }),
      finalize(() => {
      })
      );
  }
  forgotPassword(model:any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post<any>(environment.personApiUrl+'/api/v1/forgotpassword', model, { headers }).pipe(
      map((res) => {
        if(res.code==1){
          this._router.navigate(['login'])
        } 
        return res;   
      }),
      catchError(error => {
        return throwError(error);

      }),
      finalize(() => {
      })
    );
  }
  tokenControl():boolean{
    const token=localStorage.getItem('ut')
    if(token){
      return this.jwtHelper.isTokenExpired(token);
    }
    return false
  }
  getAccessToken():string | null {
    return localStorage.getItem('ut');
  }
  getRefreshToken():string | null{
    return localStorage.getItem('rt');
  }
  refreshToken() {
    let params = new HttpParams()
      .set("refreshToken", "localStorage.getItem('rt')");
    const token = localStorage.getItem('ut');
    var httpHeaders: any;
      httpHeaders = new HttpHeaders().set(
        "Authorization",
        "Bearer " + token
      );
      let params1 = new HttpParams();
      params1 = params;
      var options1: {
        headers?: HttpHeaders;
        observe?: "body";
        params?: HttpParams;
        reportProgress?: boolean;
        withCredentials?: boolean;
      } = {
        headers: httpHeaders,
        params: params1
      };
      return this.http.get<any>(environment.userApiUrl, options1).pipe(tap((tokens:any) => {
        localStorage.setItem('ut', tokens.access_token);
        localStorage.setItem('rt', tokens.refresh_token);
      }));
  }
  getJwtToken(){
    return "localStorage.getItem('rt')";
  }
  async createPerson(model:any): Promise<any> {
    const result = await this._network.post(environment.personApiUrl+"/api/v1/person",model);
    return result;
  }
  async createCompany(model:any): Promise<any> {
    const result = await this._network.post(environment.companyApiUrl+"/api/v1/company",model);
    return result;
  }
  async getRoles(limit:any,skip:any): Promise<any> {
    let params=new HttpParams()
    .set("limit",limit)
    .set("skip",skip)
    const result = await this._network.getWithOptions(environment.personApiUrl+"/api/v1/roles",params);
    return result;
  }
  async personRole(model:any): Promise<any> {
    const result = await this._network.post(environment.personApiUrl+"/api/v1/personrole",model);
    return result;
  }
}
