import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { format, formatDistanceToNow, formatDuration, formatISO, intervalToDuration } from 'date-fns';
import { de } from 'date-fns/locale';
import { Subject } from 'rxjs';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  resetToken = null;
  currentLang='tr-TR';
  currentTheme='light-mode'
  translateData: any = {};
  user:any;
  private _loading: boolean = false;
  loadingStatus: any = new Subject();
  refreshTokenControl: boolean = false;
  constructor(
    private _snackBar: MatSnackBar,
    private _http: HttpClient, 
  ) { }

  // Tarih Formatlama
  dateFormat(date: string, version: number,date2?:string) {
    switch (version) {
      case 1: // Örnek çıktı : yaklaşık 4 saat önce
        var cd=date.split('T')[0];
        let time=date.split('T')[1].split('Z')[0];
        var result = formatDistanceToNow(new Date(cd+' '+time), { includeSeconds: true, addSuffix: true, locale: de });
        return result;
      case 2: // Örnek çıktı : 2020/10/15 09:00
        var transformData = format(new Date(date), 'yyyy-MM-dd HH:mm');
        return transformData;
      case 3: // Örnek çıktı : 2020/10/15 09
        var transformData = format(new Date(date), 'yyyy-MM-dd HH');
        return transformData;
      case 4:// Örnek çıktı : 2020/10/15
        var transformData = format(new Date(date), 'yyyy-MM-dd');
        return transformData;
      case 5:// Örnek çıktı : 2019-09-18T19:00:52Z
        const iso = formatISO(new Date(date));
        return iso;
      case 6:// Örnek çıktı : 1 gün 5 saat 9 dakika önce
        var transformData = format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
        var lastUpdate = intervalToDuration({ start: new Date(transformData), end: new Date() });
        var result = formatDuration(lastUpdate, { locale: de });
        return result;
      case 7:// Örnek Çıktı : 2020/10/15 09:00:00
        var transformData = format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
        return transformData;
      case 8:// Örnek Çıktı : 2020/10/15 09:00:00 AM
        var transformData = format(new Date(date), 'yyyy-MM-dd HH:mm:ss a');
        return transformData;
      case 9: // Örnek çıktı : yaklaşık 4 saat önce
        var cd=date.split('T')[0];
        let t=date.split('T')[1];
        var result = formatDistanceToNow(new Date(cd+' '+t), { includeSeconds: true, addSuffix: true, locale: de });
        return result;
      case 10:// Örnek çıktı : 1 gün 5 saat 9 dakika önce
        var transformData = format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
        var transformData1 = format(new Date(date2!), 'yyyy-MM-dd HH:mm:ss');
        var lastUpdate = intervalToDuration({ start: new Date(transformData), end: new Date(transformData1) });
        var result = formatDuration(lastUpdate, { locale: de });
        return result;
      default:
        return null;
    }
  }
  addDays(date:any, days:any) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  // Depth-first search algoritması
  dfs(obj: any, child: any, property: any, target: any) {
    if (obj[property] === target) {
      return obj
    }
    if (obj[child]) {
      for (let item of obj[child]) {
        let check: any = this.dfs(item, child, property, target)
        if (check) {
          return check
        }
      }
    }
    return null
  }
  groupBy(objectArray: any, property: any) {
    return objectArray.reduce(function (acc: any, obj: any) {
      var key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }
  language(lang: string): Promise<{}> {
    return new Promise<any>((resolve, reject) => {
      const langPath = `assets/i18n/i18n.json`;   
      this._http.get<any>(langPath).subscribe(
        (translation:any) => {
          Object.keys(translation).forEach((key) => {
            translation[key] = translation[key][lang];
          });
          this.translateData = Object.assign({}, translation || {});
          resolve(this.translateData);
        },
        error => {
          console.log(error);
          this.translateData = {};
          resolve(this.translateData);
        }
      );
    });
  }
  languageDetection(){
    let lang = window.navigator.languages ? window.navigator.languages[0] : null;
    lang = lang || window.navigator.language;

      let shortLang = lang;
      if (shortLang.indexOf('-') !== -1)
          shortLang = shortLang.split('-')[0];

      if (shortLang.indexOf('_') !== -1)
          shortLang = shortLang.split('_')[0];
      shortLang=="de" ? this.language("de-DE"):shortLang=="tr" ? this.language("tr-TR"):this.language("en-US")
  }
  transform(key: any): any {
    return this.translateData[key];
  }
  get loading():boolean {
    return this._loading;
  }

  set loading(value) {
    this._loading = value;
    this.loadingStatus.next(value);
  }

  startLoading() {
    this.loading = true;
  }

  stopLoading() {
    this.loading = false;
  }
  snackbar(message: string, code: boolean) {
    this._snackBar.open(message, '', { panelClass: ['mat-toolbar', code ? 'mat-primary' : 'mat-warn'], duration: 3000, horizontalPosition: 'right', verticalPosition: 'bottom' });
  }
  toDataURL(url:any, callback:any) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.responseType = 'blob';
    xhr.send();
  }

  generatePdf(content: any,styles:any) {
    let docDefinition: any = {
      content: content,
      styles:styles
    };
    pdfMake.createPdf(docDefinition).open();
  }
  deviceCountinuingParkingTime(parkSlot:any){
    let parkingTime=parseFloat(parkSlot.parkingTime);
    if(parkingTime<60) {
       return Math.trunc(parkingTime)+' min'
    }
    if(parkingTime>=60 && parkingTime<1440){
     let remainderMinute=Math.trunc((parkingTime%60))+'min';
      return (~~(parkingTime/60))+'Std '+remainderMinute;
    }
    let totalHour=(parkingTime/60);
    let totalDay=Math.trunc((totalHour/24))+'Tag ';
    let remainderHour=Math.trunc((totalHour % 24))+'Std ' 
     return totalDay+remainderHour;
    }
 
}
